import { useEffect, useState } from 'react';
import { SegmentedProgressBar } from '../../../../components/SegmentedProgressBar/SegmentedProgressBar';
import { ErrorCountry, Recipient } from './Recipient';
import ArrowIcon from 'assets/Icon_Form_Arrow_02.png';
import styles from './MakePayment.module.scss';
import { PayeeFormState } from '../EditPayee';
import { PaymentDetails, PaymentDetailsForm } from './PaymentDetails';
import { AmountPurpose, AmountPurposeForm } from './AmountPurpose';
import { ApiResponse, useFetch } from '../../../../api';
import { endpoints } from '../../../../endpoints.config';
import { MoreDetails, MoreDetailsForm } from './MoreDetails';
import { ReviewPayment } from './ReviewPayment';
import { useGetFiatAccountDetails } from '../../../../helpers/useGetFiatAccountDetails';
import { ProductType } from '../../../../components/sideMenu/SideMenu';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { useCreatePayeeInitalValues } from '../helpers';

const STEPS = [
    { label: 'Recipient', stepNumber: 1 },
    { label: 'Payment Details', stepNumber: 2 },
    { label: 'Amount & Purpose', stepNumber: 3 },
    { label: 'More Details', stepNumber: 4 },
    { label: 'Review', stepNumber: 5 },
];

export type FeeOption = {
    bankFeeSplitType: string;
    bankFeeSplitTypesId: number;
    minFee: number;
    feePercent: number;
    tempHoldFee?: string;
    fixedAdditionalFee?: number;
};

export type AccountFeeInfo = {
    balance: number | null;
    transferTypes: {
        transferType: string;
        feeOptions: FeeOption[];
    }[];
};

export type MakePaymentForm = PayeeFormState & {
    accountType: string;
    allowedTransferTypes: string;
    bInternational: boolean;
    id: number; // payee id
    bSavePayee: boolean;
} & PaymentDetailsForm &
    AmountPurposeForm &
    MoreDetailsForm;

export type PaymentStepProps = {
    nextStep: (values?: any) => void;
};

type Props = {
    goBack: () => void;
    payee?: Pick<
        PayeeFormState,
        | 'payeeType'
        | 'countryCode'
        | 'addressCountryCode'
        | 'name'
        | 'accountName'
        | 'payeesReference'
        | 'routingNumber'
        | 'accountNumber'
        | 'swiftNumber'
        | 'iban'
    > & {
        id: number;
        allowedTransferTypes: string;
        bInternational: boolean;
        accountType: string;
    };
};

export type CountryOptionResponse = {
    name: string;
    countryISO3: string;
    states: StateOptionResponse[] | null;
    crbErrorCode: string;
    dbsErrorCode: string;
};

export type StateOptionResponse = {
    id: number;
    isoCode: string;
    name: string;
};

export type CountryOptions = SelectOption & {
    states: SelectOption[] | null;
};

export const useFetchPayeeCountries = () => {
    const accountDetails = useGetFiatAccountDetails();
    const isCrb = accountDetails?.productDisplayName === ProductType.CRB;
    const isDbs =
        accountDetails?.productDisplayName === ProductType.DBS ||
        accountDetails?.productDisplayName === ProductType.DBS_TMP;

    const { data: countries } = useFetch<{ details: CountryOptionResponse[] }>(
        endpoints.accounts.availablePayeeCountries,
        undefined,
        {
            details: [
                {
                    name: 'United States',
                    countryISO3: 'USA',
                    states: null as StateOptionResponse[] | null,
                    crbErrorCode: '',
                    dbsErrorCode: '',
                },
            ],
        }
    );
    const availableCountries =
        countries?.details.map((country) => ({
            label: country.name,
            value: country.countryISO3,
            states: country.states
                ? country.states.map((s) => ({ label: s.name, value: s.isoCode }))
                : null,
        })) ?? [];
    const errorCountries: ErrorCountry[] = (countries?.details ?? [])
        .filter((country) => (isCrb && !!country.crbErrorCode) || (isDbs && !!country.dbsErrorCode))
        .map((country) => ({
            countryISO3: country.countryISO3,
            name: country.name,
            errorCode: isCrb ? country.crbErrorCode : country.dbsErrorCode,
        }));

    return { availableCountries, errorCountries };
};

export const MakePayment: React.FC<Props> = ({ goBack, payee }) => {
    const [steps, setSteps] = useState(payee ? STEPS.slice(1) : STEPS);
    const [currentStep, setCurrentStep] = useState(steps[0].stepNumber);
    const [paymentData, setPaymentData] = useState<Partial<MakePaymentForm>>(payee ?? {});

    const accountDetails = useGetFiatAccountDetails();

    const {
        data,
        error,
        loading: loadingPurposes,
        reload,
    } = useFetch<ApiResponse<{ code: string; description: string }[]>>(
        endpoints.accounts.getPurposeCodes
    );
    const { availableCountries, errorCountries } = useFetchPayeeCountries();

    const { initialValues: payeeData } = useCreatePayeeInitalValues(payee?.id);

    const {
        data: feeDetails,
        loading: loadingFees,
        reload: reloadFees,
    } = useFetch<ApiResponse<AccountFeeInfo>>(endpoints.accounts.feeDetails, {
        params: {
            customerAssetAccountsId: accountDetails?.id + '',
            payeesId: payee ? payee.id + '' : paymentData.id,
        },
        withCredentials: false,
    });
    useEffect(() => {
        reloadFees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentData.id, payee?.id]);

    useEffect(() => {
        setPaymentData((prev) => ({
            ...prev,
            ...payeeData,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payeeData]);

    useEffect(() => {
        if (error) reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const purposes = data
        ? data.details.map((item) => ({ label: item.description, value: item.code }))
        : [];

    const prevStep = () => {
        if (currentStep === steps[0].stepNumber) {
            goBack();
        } else setCurrentStep((prev) => prev - 1);
    };
    const nextStep = (values: any) => {
        if (currentStep === steps[steps.length - 1].stepNumber) {
            goBack();
        } else {
            setPaymentData((prev) => ({
                ...prev,
                ...values,
            }));
            setCurrentStep((prev) => prev + 1);
        }
    };

    const step = STEPS.find((step) => step.stepNumber === currentStep)!;

    const handleEditPayee = () => {
        setSteps(STEPS);
        setCurrentStep(STEPS[0].stepNumber);
    };
    return (
        <div className={`MoveMoneySubPage ${styles.MakePaymentWrapper}`}>
            <div className={styles.MakePayment}>
                <h1>Make payment</h1>
                <SegmentedProgressBar currentStepNumber={currentStep} steps={steps} />
                <div className={styles.MakePaymentHeader}>
                    <button onClick={prevStep} type="button" className={styles.BackButton}>
                        <img alt="Back" src={ArrowIcon} />
                    </button>
                    <h2>{step.label}</h2>
                </div>

                {currentStep === 1 && (
                    <Recipient
                        availableCountries={availableCountries}
                        errorCountries={errorCountries}
                        initialValues={{
                            id: paymentData.id,
                            payeeType: paymentData.payeeType,
                            countryCode: paymentData.countryCode,
                            accountName: paymentData.accountName,
                            name: paymentData.name,
                            payeesReference: paymentData.payeesReference,
                            routingNumber: paymentData.routingNumber,
                            swiftNumber: paymentData.swiftNumber,
                            intermediaryBic: paymentData.intermediaryBic,
                            accountNumber: paymentData.accountNumber,
                            iban: paymentData.iban,
                            bankName: paymentData.bankName,
                            addressLine1: paymentData.addressLine1,
                            addressLine2: paymentData.addressLine2,
                            townCity: paymentData.townCity,
                            state: paymentData.state,
                            postCode: paymentData.postCode,
                            addressCountryCode: paymentData.addressCountryCode,
                            transmitter: paymentData.transmitter,
                            bSavePayee: true,
                        }}
                        nextStep={nextStep}
                    />
                )}
                {currentStep === 2 && (
                    <PaymentDetails
                        initialValues={{
                            paymentReference: paymentData.paymentReference ?? '',
                            ...(paymentData.transferType && {
                                transferType: paymentData.transferType,
                            }),
                        }}
                        paymentMethods={paymentData.allowedTransferTypes ?? ''}
                        nextStep={nextStep}
                    />
                )}
                {currentStep === 3 && (
                    <AmountPurpose
                        accountFeeInfo={
                            feeDetails?.details ?? {
                                balance: null,
                                transferTypes: [],
                            }
                        }
                        loading={loadingFees || loadingPurposes}
                        purposes={purposes}
                        bInternational={!!paymentData.bInternational}
                        transferType={paymentData.transferType!}
                        accountType={paymentData.accountType}
                        initialValues={{
                            amount: paymentData.amount ?? 0,
                            purpose: paymentData.purpose ?? '',
                            ...(paymentData.feeId && { feeId: paymentData.feeId ?? 0 }),
                        }}
                        nextStep={nextStep}
                    />
                )}
                {currentStep === 4 && (
                    <MoreDetails
                        initialValues={{
                            attachments: paymentData.attachments ?? [],
                            memo: paymentData.memo ?? '',
                            notes: paymentData.notes ?? '',
                        }}
                        nextStep={nextStep}
                    />
                )}
                {currentStep === 5 && (
                    <ReviewPayment
                        availableCountries={availableCountries}
                        purposes={purposes}
                        accountFeeInfo={
                            feeDetails?.details ?? {
                                balance: 0,
                                transferTypes: [],
                            }
                        }
                        data={paymentData as MakePaymentForm}
                        nextStep={nextStep}
                        handleEditPayee={handleEditPayee}
                    />
                )}
            </div>

            {paymentData && paymentData.name && (
                <div className={styles.PayeeNameWrapper}>
                    <div className={styles.PayeeInitials}>
                        {paymentData.name.split(' ').reduce((prev, cur, i, arr) => {
                            if (i === 0 || i === arr.length - 1) {
                                return prev + cur[0].toUpperCase();
                            } else return prev;
                        }, '')}
                    </div>
                    <div className={styles.PayeeName}>{paymentData.name}</div>
                </div>
            )}
        </div>
    );
};
