import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'components/modal/Modal';
import Button from 'components/button/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CheckboxInput } from 'components/form/CheckboxInput';
import styles from './ESignModal.module.scss';
import PDFViewer from 'components/pdf/PDFViewer';
type Props = {
    close(): void;
    programType?: string;
    onConcentAccepted: () => void;
};
const validationSchema = Yup.object({
    acceptConsent: Yup.boolean()
        .oneOf([true], 'You must accept the E-Sign Consent to proceed.')
        .required('Required'),
    acceptCHA: Yup.boolean()
        .oneOf([true], 'You must accept the Cardholder Agreement to proceed.')
        .required('Required'),
});
function getHtmFile(programType: string | undefined): string {
    if (programType === 'CorporateCommercialPrepaid') return '/e-sign/E-Sign_Commercial_PDF.pdf';
    else if (programType === 'InstantIssuePrepaid') return '/e-sign/E-Sign_Instant_Issue_PDF.pdf';
    return '/e-sign/E-Sign_Reloadable_PDF.pdf';
}
function getCArdTypleTitle(programType: string | undefined): string {
    if (programType === 'CorporateCommercialPrepaid') return 'Corporate Commercial';
    else if (programType === 'InstantIssuePrepaid') return 'Instant Issue';
    return 'Reloadable Consumer';
}
function ESignModal(props: Props) {
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const [isConcentAccepted, setIsConcentAccepted] = useState(false);
    const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const handleScroll = (event: { target: any }) => {
        const target = event.target;
        const scrollY = target.scrollTop;
        const innerHeight = target.clientHeight;
        const scrollHeight = target.scrollHeight;
        if (scrollY + innerHeight + 10 >= scrollHeight) {
            setIsScrolledToEnd(true);
        }
    };
    const initialValues = {
        acceptConsent: false,
        acceptCHA: false,
    };
    const handleAcceptESign = () => {
        setIsConcentAccepted((prevValue) => !prevValue);
    };
    const handleAgreementChange = () => {
        setIsAgreementAccepted((prevValue) => !prevValue);
    };
    return (
        <Modal
            title={`Cardholder Agreement (${getCArdTypleTitle(props.programType)})`}
            onCloseModal={props.close}
            className={styles.modal_content}
            customButtons={
                <div className="ModalNavigation">
                    <Button priority="secondary" type="button" onClick={props.close}>
                        Cancel
                    </Button>
                    <Button
                        priority="primary"
                        type="submit"
                        disabled={!isConcentAccepted || !isAgreementAccepted}
                        className="Btn BtnRed"
                        onClick={props.onConcentAccepted}
                    >
                        Order Card
                    </Button>
                </div>
            }
        >
            <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={(values) => {}}
            >
                {({ errors, touched, isValid, values }) => (
                    <Form>
                        <div
                            ref={iframeRef}
                            style={{
                                height: '300px',
                                overflowY: 'auto',
                                border: '1px solid #cccccc',
                                borderRadius: '10px',
                                padding: '0px',
                                fontSize: '0.9rem',
                                marginBottom: '20px',
                            }}
                            onScroll={handleScroll}
                        >
                            <PDFViewer fileUrl={getHtmFile(props.programType)} />
                        </div>
                        {/* <iframe
                            src={getHtmFile(props.programType)}
                            ref={iframeRef}
                            width="100%"
                            height="100%"
                            style={{
                                height: '300px',
                                overflowY: 'hidden',
                                border: '1px solid #cccccc',
                                borderRadius: '10px',
                                padding: '0px',
                                fontSize: '0.9rem',
                                marginBottom: '20px',
                            }}
                            title="a"
                        /> */}
                        <br />
                        <div>
                            <CheckboxInput
                                disabled={!isScrolledToEnd}
                                name="acceptConsent"
                                label={`I accept the E-Sign Consent`}
                                onChange={handleAcceptESign}
                                checked={isConcentAccepted}
                            />
                            {errors.acceptConsent && touched.acceptConsent && (
                                <div style={{ color: 'red' }}>{errors.acceptConsent}</div>
                            )}
                        </div>
                        <br />
                        <div>
                            <CheckboxInput
                                disabled={!isScrolledToEnd}
                                name="acceptCHA"
                                label={`I accept the Cardholder Agreement`}
                                onChange={handleAgreementChange}
                                checked={isAgreementAccepted}
                            />
                            {errors.acceptCHA && touched.acceptCHA && (
                                <div style={{ color: 'red' }}>{errors.acceptCHA}</div>
                            )}
                        </div>
                        <p style={{ color: 'red', fontSize: 12, minHeight: 20 }}>
                            {!isScrolledToEnd ? (
                                'Please read through the entire consent text to continue.'
                            ) : (
                                <></>
                            )}
                            <br />
                            {errorMessage && errorMessage}
                        </p>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
export default ESignModal;