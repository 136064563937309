import { Form, Formik, FormikHelpers } from 'formik';
import api, { ApiResponse, isAxiosErrorHandled } from '../../../../api';
import Button from '../../../../components/button/Button';
import { endpoints } from '../../../../endpoints.config';
import {
    EMPTY_PAYEE_VALUES,
    EMPTY_TRANSMITTER_INFO,
    PayeeForm,
    PayeeFormState,
} from '../EditPayee';
import { PaymentStepProps } from './MakePayment';
import { CheckboxInput } from '../../../../components/form/CheckboxInput';
import { toCamelCase } from '../../../../helpers/formatFormFieldNames';
import { getErrorMessage } from '../../../../errors';
import { Toast } from '../../../../helpers/toast';
import { useGetFiatAccountDetails } from 'helpers/useGetFiatAccountDetails';
import { ProductType } from '../../../../components/sideMenu/SideMenu';
import { useIsFinancialInstitution, usePayeeValidationSchema } from '../helpers';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';

export type ErrorCountry = { name: string; countryISO3: string; errorCode: string };
type Props = PaymentStepProps & {
    initialValues: Partial<PayeeFormState & { bSavePayee: boolean; id?: number }>;
    hideSaveOption?: boolean;
    availableCountries: {
        label: string;
        value: string;
        states: SelectOption[] | null;
    }[];
    errorCountries: ErrorCountry[];
    btntext?: string;
};

export const Recipient: React.FC<Props> = ({
    nextStep,
    initialValues,
    hideSaveOption,
    availableCountries,
    errorCountries,
    btntext,
}) => {
    const selectedAccountDetails = useGetFiatAccountDetails();
    const bFinancialInstitution = useIsFinancialInstitution();

    const onSubmit = async (
        values: PayeeFormState & { bSavePayee: boolean },
        helpers: FormikHelpers<PayeeFormState & { bSavePayee: boolean }>
    ) => {
        try {
            let endpoint = endpoints.accounts.createPayee;

            if (initialValues.id) {
                endpoint = endpoints.accounts.editPayee;
            }

            const { postCode, ...payload } = values;

            const res = await api.post<
                ApiResponse<{ allowedTransferTypes: string; bInternational: boolean; id: number }>
            >(endpoint, {
                ...payload,
                postcode: postCode,
                bHide: !values.bSavePayee,
                currencyCode: selectedAccountDetails ? selectedAccountDetails.currencyCode : 'USD',
                customerAssetAccountsId: selectedAccountDetails?.id,
            });

            if (res.data.status === '1') {
                nextStep({
                    ...values,
                    id: res.data.details.id,
                    allowedTransferTypes: res.data.details.allowedTransferTypes,
                    bInternational: res.data.details.bInternational,
                });
            } else {
                throw new Error();
            }
        } catch (err) {
            if (isAxiosErrorHandled(err)) {
                err.response.data.errors.forEach((error) => {
                    const fieldName = toCamelCase(error.fieldName) as string;
                    if (Object.keys(validationSchema.fields).includes(fieldName)) {
                        helpers.setFieldError(fieldName, getErrorMessage(error.messageCode));
                    }
                });
            } else {
                Toast.openErrorToast('Unable to setup payee');
            }
        }
    };

    const validationSchema = usePayeeValidationSchema(errorCountries);
    const accountDetails = useGetFiatAccountDetails();

    const isDBS =
        accountDetails?.productDisplayName === ProductType.DBS ||
        accountDetails?.productDisplayName === ProductType.DBS_TMP;

    const transmitterInitialInfo = bFinancialInstitution ? EMPTY_TRANSMITTER_INFO : null;

    return (
        <Formik
            initialValues={{
                ...EMPTY_PAYEE_VALUES,
                bFinancialInstitution,
                transmitter: transmitterInitialInfo,
                bSavePayee: true,
                ...initialValues,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <PayeeForm isDBS={isDBS} availableCountries={availableCountries} />
                    {!hideSaveOption && (
                        <CheckboxInput
                            withFormStyling
                            name="bSavePayee"
                            label="Save recipient details to move money faster next time"
                        />
                    )}
                    <Button disabled={isSubmitting} type="submit" color="third">
                        {btntext ?? 'Next'}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
