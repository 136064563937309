import { format } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useCSVDownloader } from 'react-papaparse';
import Button from '../../../components/button/Button';
import { GeneralError } from '../../../components/GeneralError/GeneralError';
import { BulkTransferDeleteModal } from './BulkTransferDeleteModal';
import { BulkTransferEditModal } from './BulkTransferEditModal';
import { BulkTransferItem } from './BulkTransferItem';
import { BulkTransferUpload } from './BulkTransferUpload';
import { useCreateEmptyTemplate, parseTransferInfoForDisplay, useFetchBulkConfig } from './helpers';
import { ParsedCsv } from './models';
import { buildValidationSchema } from './validationSchema';
import { useTouchErrorFormikFields } from '../../../helpers/useTouchAllFormikFields';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { useQueryClient } from '@tanstack/react-query';
import { toCamelCase } from '../../../helpers/formatFormFieldNames';
import * as Yup from 'yup';
import { ManualErrorTracker } from './ManualErrorTracker';
import { useIsFinancialInstitution } from '../Payees/helpers';

type Props = {
    accountId?: number;
    back?: () => void;
};

type FormValues = { bulkTransfer: ParsedCsv[] };

const getFieldNamesFromArraySchema = (schema: Yup.ArraySchema<any>) => {
    const fields = [];
    const itemSchema = schema.innerType;

    if (itemSchema && itemSchema.fields) {
        fields.push(...Object.keys(itemSchema.fields));
    }

    return fields;
};

export const BulkTransfer: React.FC<Props> = ({ accountId, back }) => {
    const [bulkTransfer, setBulkTransfer] = useState<ParsedCsv[]>([]);
    const [error, setError] = useState<string | null>(null);

    const bFinancialInstitution = useIsFinancialInstitution();

    const { CSVDownloader, Type } = useCSVDownloader();
    const csvTemplateStructure = useCreateEmptyTemplate(!!accountId);

    const { countries, purposes, accounts, loading, hasError, reload } =
        useFetchBulkConfig(accountId);

    const [manualErrors, setManualErrors] = useState<{ [key: string]: string }>({});

    const [showDeleteModal, setShowDeleteModal] = useState<number | null>(null);
    const handleCloseDeleteModal = () => setShowDeleteModal(null);
    const handleDeleteTransfer = (
        values: ParsedCsv[],
        index: number,
        setFieldValue: FormikHelpers<{ bulkTransfer: ParsedCsv[] }>['setFieldValue']
    ) => {
        // Update the manual errors we have saved
        if (index < values.length - 1) {
            // Update error field names
            const updatedState: Record<string, string> = {};
            Object.keys(manualErrors).forEach((key) => {
                const match = key.match(/bulkTransfer\[(\d+)\]\.(.*)/);
                if (match) {
                    const fieldIndex = parseInt(match[1], 10);
                    const fieldname = match[2];
                    if (fieldIndex > index) {
                        // Shift the index down by one
                        const newIndex = fieldIndex - 1;
                        const newKey = `bulkTransfer[${newIndex}].${fieldname}`;
                        updatedState[newKey] = manualErrors[key];
                    } else {
                        updatedState[key] = manualErrors[key];
                    }
                } else {
                    updatedState[key] = manualErrors[key];
                }
            });
            setManualErrors(updatedState);
        }

        const newTransferList = values.filter((_, i) => i !== index);

        if (newTransferList.length === 0) setBulkTransfer([]);
        else setFieldValue('bulkTransfer', newTransferList);

        handleCloseDeleteModal();
    };
    const [showEditModal, setShowEditModal] = useState<number | null>(null);
    const handleCloseEditModal = () => setShowEditModal(null);

    const queryClient = useQueryClient();

    const handleSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        const errors: { [key: string]: string } = {};
        const handleTransfer = (transfer: ParsedCsv, i: number) => {
            if (transfer.status && transfer.status !== 'Error') return Promise.resolve();
            helpers.setFieldValue(`bulkTransfer.${i}.status`, 'Submitting');
            const formdata = new FormData();
            const {
                payeeId,
                sourceAccountId,
                paymentReference,
                feeId,
                transferType,
                amount,
                purpose,
                memo,
                notes,
            } = transfer;
            const payload = {
                transferType,
                amount,
                purpose,
                memo,
                notes,
            };

            formdata.append('customerAssetAccountsId', sourceAccountId + '');
            formdata.append('payeesId', typeof payeeId === 'number' ? payeeId + '' : '');
            formdata.append('bHide', true + '');
            feeId && formdata.append('bankFeeSplitType', feeId + '');
            formdata.append('reference', paymentReference + '');
            Object.entries(payload).forEach(([key, value]) => {
                formdata.append(key, value + '');
            });

            const executeExchange = (formData: FormData) =>
                instance
                    .post<ApiResponse<{ bApproved: boolean }>>(
                        endpoints.accounts.createExternalTransfer,
                        formdata,
                        {
                            headers: { 'content-type': 'multipart/form-data' },
                        }
                    )
                    .then((res) => {
                        if (res.data.status === '1') {
                            if (res.data.details.bApproved)
                                return helpers.setFieldValue(
                                    `bulkTransfer.${i}.status`,
                                    'Success',
                                    false
                                );
                            return helpers.setFieldValue(
                                `bulkTransfer.${i}.status`,
                                'Success - Pending Approval',
                                false
                            );
                        } else
                            return helpers.setFieldValue(
                                `bulkTransfer.${i}.status`,
                                'Error',
                                false
                            );
                    })
                    .catch((err) => {
                        helpers.setFieldValue(`bulkTransfer.${i}.status`, 'Error', false);
                        let errorCode = 'GENERIC';
                        if (isAxiosErrorHandled(err)) {
                            if (
                                err.response.data.errors?.some((err) =>
                                    err.fieldName.toLowerCase().includes('transmitter')
                                )
                            ) {
                                errorCode = 'MISSING_TRANSMITTER_INFO';
                            } else {
                                errorCode = err.response.data.errors?.[0]?.messageCode ?? 'GENERIC';
                            }
                        }
                        helpers.setFieldError(`bulkTransfer.${i}`, getErrorMessage(errorCode));
                    });
            if (payeeId) return executeExchange(formdata);
            else
                return instance
                    .post<
                        ApiResponse<{
                            allowedTransferTypes: string;
                            bInternational: boolean;
                            id: number;
                        }>
                    >(endpoints.accounts.createPayee, {
                        ...transfer,
                        bHide: true,
                        customerAssetAccountsId: sourceAccountId,
                    })
                    .then((res) => {
                        formdata.set('payeesId', res.data.details.id + '');
                        /* TODO: Need to be carfeul here. If we create a payee once then the transfer fails we don't want
                        to keep creating new payees when we retry. They're all set to hide so the user won't see them
                        But it's feasable the client will want to save payees here so we'll need to do the below steps.

                        Actions: 
                        Set payeeId to new payee value
                        Clear old payee fields for clarity (address lines etc)
                        Refetch bulk transfers GET data to refresh payee list
                        */
                        helpers.setFieldValue(
                            `bulkTransfer.${i}.payeeId`,
                            res.data.details.id,
                            false
                        );

                        return executeExchange(formdata);
                    })
                    .catch((err) => {
                        helpers.setFieldValue(`bulkTransfer.${i}.status`, 'Error', false);
                        let errorCode = 'GENERIC';
                        if (isAxiosErrorHandled(err)) {
                            err.response.data.errors.forEach((error) => {
                                const camelFieldName = toCamelCase(error.fieldName) as string;
                                const fieldName = `bulkTransfer[${i}].${camelFieldName}`;

                                if (
                                    getFieldNamesFromArraySchema(
                                        validationSchema.fields.bulkTransfer
                                    ).includes(camelFieldName)
                                ) {
                                    errors[fieldName] = getErrorMessage(error.messageCode);
                                }
                            });
                        } else {
                            helpers.setFieldError(`bulkTransfer.${i}`, getErrorMessage(errorCode));
                        }
                    });
        };
        async function handleTransfers() {
            for (const [index, transfer] of values.bulkTransfer.entries()) {
                await handleTransfer(transfer, index);
            }
        }
        handleTransfers().then(() => {
            helpers.setSubmitting(false);
            Object.entries(errors).forEach(([key, value]) => helpers.setFieldError(key, value));
            setManualErrors(errors);
            // Reload the bulk transfers data to get latest account balances after resolved transfers
            reload();
        });
    };

    const validationSchema = buildValidationSchema(accounts, countries, purposes, queryClient);

    if (hasError)
        return (
            <div className="BulkTransferWrapper">
                <div className="BulkTransferHeader">
                    <h1>Bulk transfer</h1>
                </div>
                <div className="BulkTransferError">
                    <GeneralError
                        title="Failed to initiate"
                        message="Failed to initiate bulk transfer. Unable to fetch account details"
                    />
                    <Button onClick={reload}>Retry</Button>
                </div>
            </div>
        );

    const hasBulkTransfer = bulkTransfer && bulkTransfer.length > 0;
    return (
        <div className="BulkTransferWrapper">
            <div className="RestrictWidth">
                {back && (
                    <div className="Back" onClick={back}>
                        {'< Back'}
                    </div>
                )}
                <div className="BulkTransferHeader">
                    <h1>Bulk transfer</h1>
                    {!hasBulkTransfer && (
                        <CSVDownloader
                            type={Type.Link}
                            config={{ header: true }}
                            filename={`Bulk_transfer_${format(new Date(), 'dd/MM/yyyy')}`}
                            data={csvTemplateStructure}
                        >
                            <Button className="DownloadTemplate">Download template</Button>
                        </CSVDownloader>
                    )}
                </div>
                {error && <p className="ErrorText">{error}</p>}
                {!hasBulkTransfer && (
                    <BulkTransferUpload
                        accountId={accountId}
                        loadingData={loading}
                        accounts={accounts}
                        countries={countries}
                        purposeCodes={purposes}
                        setBulkTransfer={setBulkTransfer}
                        setError={setError}
                    />
                )}
            </div>
            {hasBulkTransfer && (
                <Formik
                    initialValues={{ bulkTransfer: bulkTransfer }}
                    validationSchema={validationSchema}
                    validateOnMount
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, setFieldValue, isSubmitting }) => {
                        const isFirstAttempt = values.bulkTransfer.every(
                            (transfer) => !transfer.status
                        );
                        const failedTransferCount = values.bulkTransfer.filter(
                            (transfer) => transfer.status === 'Error'
                        ).length;
                        // eslint-disable-next-line no-console
                        console.log('values', values);
                        return (
                            <Form>
                                <TouchErrorFields />
                                {/* Hacky method to not loose manually set errors */}
                                <ManualErrorTracker
                                    setManualErrors={setManualErrors}
                                    errors={manualErrors}
                                />
                                {typeof showDeleteModal === 'number' && (
                                    <BulkTransferDeleteModal
                                        fieldname={`bulkTransfer[${showDeleteModal}]`}
                                        accounts={accounts}
                                        handleDeleteTransfer={() =>
                                            handleDeleteTransfer(
                                                values.bulkTransfer,
                                                showDeleteModal,
                                                setFieldValue
                                            )
                                        }
                                        handleCloseModal={handleCloseDeleteModal}
                                    />
                                )}
                                {typeof showEditModal === 'number' && (
                                    <BulkTransferEditModal
                                        fieldname={`bulkTransfer[${showEditModal}]`}
                                        accountId={accountId}
                                        accounts={accounts}
                                        countries={countries}
                                        purposeCodes={purposes}
                                        handleCloseModal={handleCloseEditModal}
                                    />
                                )}
                                <div className="BulkTransferList">
                                    {(values.bulkTransfer ?? []).map((item, i) => (
                                        <BulkTransferItem
                                            key={i}
                                            errors={errors?.bulkTransfer?.[i]}
                                            accounts={accounts}
                                            countries={countries}
                                            purposeCodes={purposes}
                                            handleDeleteTransfer={() =>
                                                !isSubmitting && setShowDeleteModal(i)
                                            }
                                            handleEditTransfer={() =>
                                                !isSubmitting && setShowEditModal(i)
                                            }
                                            {...parseTransferInfoForDisplay(
                                                item,
                                                accounts,
                                                countries,
                                                purposes,
                                                bFinancialInstitution
                                            )}
                                        />
                                    ))}
                                    <div className="BulkTransferButtons">
                                        <Button
                                            type="button"
                                            priority="secondary"
                                            onClick={() => {
                                                setBulkTransfer([]);
                                                setError(null);
                                            }}
                                            disabled={isSubmitting}
                                        >
                                            {isFirstAttempt || failedTransferCount > 0
                                                ? 'Cancel'
                                                : 'Back'}
                                        </Button>
                                        {(isFirstAttempt || failedTransferCount > 0) && (
                                            <Button disabled={isSubmitting}>
                                                {isFirstAttempt
                                                    ? 'Submit'
                                                    : `Retry ${failedTransferCount} failed transfer${
                                                          failedTransferCount > 1 ? 's' : ''
                                                      }`}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </div>
    );
};

const TouchErrorFields = () => {
    useTouchErrorFormikFields(true);

    return null;
};
