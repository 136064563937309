import {
    WrappedFormSingleSelectField as FormSingleSelectField,
    SelectOption,
} from 'components/form/FormSingleSelectField';
import { Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import api, { ApiResponse, isAxiosErrorHandled } from '../../../api';
import FormTextField from '../../../components/form/FormTextField';
import { RadioButtons } from '../../../components/radiobuttons/radiobuttons';
import { endpoints } from '../../../endpoints.config';
import { useGetFiatAccountDetails } from '../../../helpers/useGetFiatAccountDetails';
import { selectPayeeSubpage } from '../../../reducers/payee';
import { FoldingCube } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';
import { GeneralError } from '../../../components/GeneralError/GeneralError';
import { Toast } from '../../../helpers/toast';
import { processErrors } from '../../../helpers/categoryHelpers/processErrors';
import Button from '../../../components/button/Button';
import { ReactComponent as WarningIcon } from 'assets/ui-update/warning.svg';
import { ProductType } from '../../../components/sideMenu/SideMenu';
import { AddressLookup } from './MakePayment/AddressLookup/AddressLookup';
import { CountryOptions, useFetchPayeeCountries } from './MakePayment/MakePayment';
import {
    useCreatePayeeInitalValues,
    useIsFinancialInstitution,
    usePayeeValidationSchema,
} from './helpers';
import { FormDateField } from '../../../components/form/FormDateField';

export type EditPayeeResponse = {
    id: number;
    accountName: string;
    name: string;
    payeesReference: string;
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    state: string;
    postcode: string;
    addressCountryCode: string;

    type: PayeeType;
    achAccountNumber: string;
    achRoutingNumber: string;
    swiftNumber: string;
    intermediaryBic: string;
    iban: string;
    bankName: string;
    countryCode: string;

    accountType: string;
    addDate: string;
    addedBy: string;
    allowedTransferTypes: string;
    bInternational: boolean;
    bPullAvailable: boolean;
    cardLast4Digits: null;
    countryName: string;
    currencyCode: string;

    transmitterType: TransmitterType;
    transmitterName: string;
    transmitterDateOfBirth?: string;
    transmitterFirstPartyTransfer: boolean;
    transmitterAccountNumber: string;
    transmitterAddressLine1: string;
    transmitterAddressLine2: string;
    transmitterCountryCode: string;
    transmitterPostcode: string;
    transmitterState: string;
    transmitterTownCity: string;
};

export enum PayeeType {
    Personal = 'Personal',
    Company = 'Company',
}
export enum TransmitterType {
    Personal = 'Individual', // Client quoted this value hence not using "Personal"
    Company = 'Company',
}

export type TransmitterInfo = {
    firstPartyTransfer: boolean;
    type: TransmitterType | null;
    name: string;
    dateOfBirth?: string;
    accountNumber?: string;
    addressLine1: string;
    addressLine2?: string;
    townCity: string;
    state: string;
    postcode: string;
    countryCode: string;
};

export type PayeeFormState = {
    payeeType: PayeeType;
    bankName: string;
    countryCode: string;
    accountName: string;
    name: string;
    payeesReference: string;

    routingNumber: string;
    swiftNumber: string;
    intermediaryBic: string;
    accountNumber: string;

    iban: string;

    bFinancialInstitution: boolean;
    transmitter: TransmitterInfo | null;
} & PayeeAddress;

export type PayeeAddress = {
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    state: string;
    postCode: string;
    addressCountryCode: string;
};

export const EMPTY_PAYEE_VALUES: PayeeFormState = {
    payeeType: PayeeType.Personal,
    countryCode: '',
    accountName: '',
    name: '',
    payeesReference: '',
    routingNumber: '',
    swiftNumber: '',
    intermediaryBic: '',
    accountNumber: '',
    iban: '',
    bankName: '',
    addressLine1: '',
    addressLine2: '',
    townCity: '',
    state: '',
    postCode: '',
    addressCountryCode: '',
    bFinancialInstitution: false,
    transmitter: null,
};
export const EMPTY_TRANSMITTER_INFO: TransmitterInfo = {
    firstPartyTransfer: false,
    type: null,
    name: '',
    dateOfBirth: undefined,
    accountNumber: '',
    addressLine1: '',
    addressLine2: '',
    townCity: '',
    state: '',
    postcode: '',
    countryCode: '',
};

type Props = {
    onBack: () => void;
};

export const EditPayee: React.FC<Props> = ({ onBack }) => {
    const { colors } = useTheme();

    const { payee } = useSelector(selectPayeeSubpage);

    const { availableCountries, errorCountries } = useFetchPayeeCountries();

    const { initialValues, loading, error } = useCreatePayeeInitalValues(payee?.payees__Id);

    const onSubmit = async (values: PayeeFormState, helpers: FormikHelpers<PayeeFormState>) => {
        try {
            const { postCode, ...payload } = values;
            const res = await api.post<ApiResponse<any>>(endpoints.accounts.editPayee, {
                id: payee?.payees__Id,
                postcode: postCode,
                ...payload,
            });

            if (res.data.status === '1') {
                Toast.openSuccessToast('Payee details updated');
                onBack();
            } else {
                throw new Error();
            }
        } catch (err) {
            if (isAxiosErrorHandled(err)) {
                const { errors } = err.response.data;
                if (errors.length > 0) {
                    helpers.setSubmitting(false);
                    const errorsObj = processErrors(errors);
                    helpers.setErrors(errorsObj);

                    if (errorsObj['error_Label']) {
                        Toast.openGenericErrorToast();
                    }
                } else {
                    Toast.openErrorToast('Failed to update details');
                }
            } else {
                Toast.openErrorToast('Failed to update details');
            }
        }
    };

    const validationSchema = usePayeeValidationSchema(errorCountries);

    const accountDetails = useGetFiatAccountDetails();

    const isDBS =
        accountDetails?.productDisplayName === ProductType.DBS ||
        accountDetails?.productDisplayName === ProductType.DBS_TMP;

    if (loading)
        return (
            <div className="EditPayeePage">
                <div className="Loading">
                    <FoldingCube color={colors.first} size={80} />
                    <p>Loading payee</p>
                </div>
            </div>
        );
    if ((!loading && error) || !initialValues)
        return (
            <div className="EditPayeePage">
                <div className="Error">
                    <GeneralError message="Unable to load payee details" />
                </div>
            </div>
        );

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <Form className="EditPayeePage">
                    <h1>Edit payee details</h1>

                    <>
                        <PayeeForm isDBS={isDBS} isEdit availableCountries={availableCountries} />
                        <UpdateWarning />
                        <Button type="submit" disabled={isSubmitting} color="third">
                            Save
                        </Button>
                    </>
                </Form>
            )}
        </Formik>
    );
};

type PayeeFormProps = {
    availableCountries: CountryOptions[];
    isEdit?: boolean;
    fieldnamePrefix?: string;
    defaultShowLookup?: boolean;
    isDBS: boolean;
};

export const PayeeForm: React.FC<PayeeFormProps> = ({
    isEdit = false,
    availableCountries,
    fieldnamePrefix,
    defaultShowLookup,
    isDBS,
}) => {
    const { values, isSubmitting, setFieldValue } = useFormikContext<
        PayeeFormState & { [key: string]: any }
    >();

    const bFinancialInstitution = useIsFinancialInstitution();

    const [payeeNameMessage, setPayeeNameMessage] = useState('Maximum 35 characters');
    const buildFieldname = useCallback(
        (name: string) => (fieldnamePrefix ? `${fieldnamePrefix}.${name}` : name),
        [fieldnamePrefix]
    );

    const [
        { value: countryCode },
        { touched: countryCodeTouched },
        { setTouched: setCountryCodeTouched },
    ] = useField(buildFieldname('countryCode'));

    const onChangeCountry = useCallback(
        (
            value: SelectOption | null,
            setFieldValue?: FormikHelpers<PayeeFormState>['setFieldValue']
        ) => {
            !!value && !countryCodeTouched && setCountryCodeTouched(true);

            if (!setFieldValue || !value || !countryCode) return;
            if (value.value === 'USA') {
                setFieldValue(buildFieldname('iban'), '', false);
                !isDBS && setFieldValue(buildFieldname('swiftNumber'), '', false);
                return;
            }
            setFieldValue(buildFieldname('routingNumber'), '', false);
            setFieldValue(buildFieldname('accountNumber'), '', false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [countryCodeTouched, setCountryCodeTouched, countryCode, isDBS]
    );

    /**
     * Method to update the payee name on input change.
     * This method calculates the remaining characters and updates the payee name message
     * in real-time as the user types in the input field.
     *
     */
    const handlePayeeNameChange = useCallback(
        (e: { target: { value: any } }) => {
            const value = e.target.value;
            if (value.length > 0) {
                setPayeeNameMessage(`${35 - value.length} characters remaining`);
            } else {
                setPayeeNameMessage('Maximum 35 characters');
            }

            setFieldValue(buildFieldname('name'), value);
        },
        [buildFieldname, setFieldValue]
    );

    const transmitterFieldnamePrefix = fieldnamePrefix
        ? `${fieldnamePrefix}.transmitter`
        : 'transmitter';
    const [{ value: bFirstPartyTransfer }] = useField(
        `${transmitterFieldnamePrefix}.firstPartyTransfer`
    );
    const [{ value: bTransmitterType }] = useField<TransmitterType>(
        `${transmitterFieldnamePrefix}.type`
    );

    const handleFirstPartyTransferUpdated = (val: boolean) => {
        if (!val) return;

        // Reset values if true
        const defaultValues: TransmitterInfo = {
            ...EMPTY_TRANSMITTER_INFO,
            firstPartyTransfer: val,
        };
        setFieldValue(transmitterFieldnamePrefix, defaultValues);
    };

    return (
        <>
            <h4>Recipient details</h4>
            <RadioButtons
                options={['Personal', 'Company']}
                fieldname={buildFieldname('payeeType')}
                label="Account Type"
                disabled={isSubmitting || isEdit}
            />
            <div className="Layout">
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('name')}
                    label="Payee Name"
                    tooltip="Name of the individual or business that is the beneficiary"
                    hint={payeeNameMessage}
                    maxLength={35}
                    onChange={handlePayeeNameChange}
                />
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('payeesReference')}
                    label="Payee Reference"
                />
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('bankName')}
                    label="Bank Name"
                    tooltip="Name of the receiving bank"
                />
                <FormSingleSelectField
                    fieldName={buildFieldname('countryCode')}
                    options={availableCountries}
                    label="Bank Country"
                    handleOptionSelected={onChangeCountry}
                    dropdownProps={{ isDisabled: isSubmitting }}
                />
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('accountName')}
                    label="Account Name"
                    tooltip="Name of the account that belongs to the payee. This field may not be seen by a receiving bank."
                />

                {isDBS ? (
                    <>
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('accountNumber')}
                            label={'Account Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('swiftNumber')}
                            label={'Swift Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('intermediaryBic')}
                            label={'Intermediary Swift'}
                        />
                    </>
                ) : countryCode === 'USA' || !countryCode ? (
                    <>
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('routingNumber')}
                            label={'Routing Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('accountNumber')}
                            label={'Account Number'}
                        />
                    </>
                ) : (
                    <>
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('swiftNumber')}
                            label={'Swift Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('iban')}
                            label={'IBAN'}
                        />
                    </>
                )}
            </div>
            <h4>Recipient address</h4>
            <AddressLookup
                fieldnamePrefix={fieldnamePrefix}
                countryOptions={availableCountries}
                defaultShowLookup={
                    values[fieldnamePrefix ? `${fieldnamePrefix}.addressLine1` : 'addressLine1']
                        ? false
                        : typeof defaultShowLookup === 'boolean'
                        ? defaultShowLookup
                        : !isEdit
                }
            />
            {bFinancialInstitution && (
                <>
                    <h4>Transmitter info</h4>
                    <RadioButtons
                        label="First Party Transfer"
                        options={['Yes', 'No']}
                        booleanField
                        fieldname={`${transmitterFieldnamePrefix}.firstPartyTransfer`}
                        customOnChange={handleFirstPartyTransferUpdated}
                    />
                    {bFirstPartyTransfer === false && (
                        <>
                            <RadioButtons
                                options={['Individual', 'Company']}
                                fieldname={`${transmitterFieldnamePrefix}.type`}
                                label="Type"
                                setToNullWhenOptionDoesNotExist
                            />
                            <FormTextField
                                label="Name"
                                field={`${transmitterFieldnamePrefix}.name`}
                            />
                            {bTransmitterType === TransmitterType.Personal && (
                                <FormDateField
                                    field={`${transmitterFieldnamePrefix}.dateOfBirth`}
                                    sideBySide={false}
                                    label="Date of Birth"
                                    datePickerProps={{
                                        maxDate: new Date(),
                                        showYearDropdown: true,
                                        scrollableYearDropdown: true,
                                        yearDropdownItemNumber: 80,
                                        onChange: (date) => {
                                            if (date) {
                                                const utcDate = new Date(
                                                    Date.UTC(
                                                        date.getFullYear(),
                                                        date.getMonth(),
                                                        date.getDate()
                                                    )
                                                );
                                                setFieldValue(
                                                    `${transmitterFieldnamePrefix}.dateOfBirth`,
                                                    utcDate
                                                );
                                            }
                                        },
                                    }}
                                />
                            )}
                            <FormTextField
                                label="Account Number"
                                field={`${transmitterFieldnamePrefix}.accountNumber`}
                                labelExtraInfo="Optional"
                            />
                            <AddressLookup
                                fieldnamePrefix={transmitterFieldnamePrefix}
                                countryOptions={availableCountries}
                                defaultShowLookup={
                                    values[transmitterFieldnamePrefix] &&
                                    values[transmitterFieldnamePrefix].addressLine1
                                        ? false
                                        : typeof defaultShowLookup === 'boolean'
                                        ? defaultShowLookup
                                        : !isEdit
                                }
                                fieldNamesMap={{
                                    addressLine1: 'addressLine1',
                                    addressLine2: 'addressLine2',
                                    townCity: 'townCity',
                                    state: 'state',
                                    postCode: 'postcode',
                                    addressCountryCode: 'countryCode',
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

const UpdateWarning = () => {
    const { values, initialValues } = useFormikContext<PayeeFormState>();

    const { accountNumber, routingNumber, swiftNumber, intermediaryBic, iban } = values;
    const {
        accountNumber: initialAccountNumber,
        routingNumber: initialRoutingNumber,
        swiftNumber: initialSwiftNumber,
        intermediaryBic: initialintermediaryBic,
        iban: initalIban,
    } = initialValues;

    const showWarning =
        accountNumber !== initialAccountNumber ||
        routingNumber !== initialRoutingNumber ||
        swiftNumber !== initialSwiftNumber ||
        intermediaryBic !== initialintermediaryBic ||
        iban !== initalIban;

    return showWarning ? (
        <div className="EditPayeeWarning">
            <WarningIcon width={20} />
            <p>
                Warning! By updating this payee you may cause any pending transactions to them to
                fail
            </p>
        </div>
    ) : null;
};
