import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import Button from 'components/button/Button';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { RadioButton } from 'components/radiobuttons/radiobuttons';
import { endpoints } from 'endpoints.config';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { getTokenAndEncrypt } from 'helpers/encryption';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { InitProperties as GooglePayProperties } from 'helpers/googlePay';
import { uniq } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AssetPairsDetails,
    AvailableOnOptions,
    PriceInfo,
    selectAllCryptoPrices,
} from 'reducers/cryptoPrices';
import * as Yup from 'yup';
import {
    CardPaymentForm,
    CardPaymentFormValues_New,
    CardPaymentFormValues_Saved,
    handleSubmit_Saved,
    initialValues_New,
    initialValues_Saved,
    NewCardDetails,
    PaymentInfo,
    validationSchema_New,
    validationSchema_Saved,
    validationSchema_WpgNew,
} from './CardPaymentForm';
import { CalculatedPriceField, FiatToggle } from './QuickCoinFormComponents';
import { useWpgSdk } from './WpgIframe';
import { useFIS } from './useFIS';

export const initialValues: PurchaseDetails = {
    cardType: 'New card',
    asset: '',
    price: undefined,
    amount: undefined,
    currency: '',
    bSaveCard: true,
    quantityType: 'Amount',
    bAcceptedTermsAndConditions: false,
};
export type FormValues = PurchaseDetails;
export type PurchaseDetails = {
    cardType: 'New card' | 'Saved card' | null;
    asset: string;
    currency: string;
    price?: number;
    amount?: number;
    quantityType: 'Amount' | 'Price';
    bAcceptedTermsAndConditions: boolean;
    bSaveCard?: boolean;
};
export type SavedCardDetails = {
    paymentCardsId: number;
    cvV2: string;
};

export enum PaymentFlowType {
    TokenizationIFrame = 'TokenizationIFrame',
    Form = 'Form',
    IFrame = 'IFrame',
}

export type PaymentGatewayMidConfig = {
    bUse3ds: boolean;
    bUseApplePay: boolean;
    bUseGooglePay: boolean;
    bUseTokenization: boolean;
    googlePayDetails: GooglePayProperties;
};

type InitResponse = {
    paymentFlow: PaymentFlowType;
    encryption: { publicKey: string; keyId: string };
    idempotencyKey: string;
    paymentGatewayMidConfig: PaymentGatewayMidConfig;
};

export const QuickCoinPage = () => {
    const prices = useSelector(selectAllCryptoPrices);
    const [cryptoOptions, setCryptoOptions] = useState<SelectOption[]>([]);
    const [staticPrices, setStaticPrices] = useState<AssetPairsDetails | null>(null);
    useEffect(() => {
        if (Object.keys(prices).length !== Object.keys(staticPrices ?? []).length) {
            setStaticPrices(prices);
        }
    }, [staticPrices, prices]);
    const [rules, _setRules] = useState<
        (PriceInfo & { asset: string; pairedAsset: string }) | null
    >(null);
    const updateRules = useCallback(
        (asset: string, pairedAsset: string) => {
            if (staticPrices && staticPrices[`${asset}/${pairedAsset}`])
                return _setRules({
                    asset,
                    pairedAsset,
                    ...staticPrices[`${asset}/${pairedAsset}`],
                });
            return _setRules(null);
        },
        [staticPrices]
    );

    const dispatch = useDispatch();

    useEffect(() => {
        const availableCryptos = uniq(
            Object.keys(prices).map((pricePair: string) => pricePair.split('/')[0])
        );
        setCryptoOptions(availableCryptos.map((ticker) => ({ label: ticker, value: ticker })));
    }, [prices]);

    const [cardType, setCardType] = useState<string>('New card');

    const [basket, setBasket] = useState<PurchaseDetails | null>(null);
    const [purchaseStage, setPurchaseStage] = useState<'basket-stage' | 'checkout-stage'>(
        'basket-stage'
    );

    const [paymentFlow, setPaymentFlow] = useState<PaymentFlowType>();
    const [encryption, setEncryption] = useState<{ publicKey: string; keyId: string } | null>(null);
    const [idempotencyKey, setIdempotencyKey] = useState<string>('');

    const [paymentReference, setPaymentReference] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [iframeUrl, setIframeUrl] = useState('');
    const [deviceSessionId, setDeviceSessionId] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [midConfig, setMidConfig] = useState<PaymentGatewayMidConfig | null>(null);

    const [cardPaymentErrMsg, setCardPaymentErrMsg] = useState('');

    useWpgSdk(paymentFlow ?? PaymentFlowType.Form);
    useEffect(() => {
        if (purchaseStage === 'basket-stage') {
            instance
                .get<ApiResponse<InitResponse>>(endpoints.quickCryptoModule.init)
                .then((res) => {
                    const {
                        paymentFlow: flow,
                        encryption: publicKeyId,
                        idempotencyKey: IdempotencyKey,
                        paymentGatewayMidConfig,
                    } = res.data.details;
                    setPaymentFlow(flow);
                    setEncryption(publicKeyId);
                    setIdempotencyKey(IdempotencyKey);
                    setMidConfig(paymentGatewayMidConfig ?? null);
                })
                .catch((err) => {
                    if (
                        isAxiosErrorHandled(err) &&
                        err?.response?.data?.errors?.some(
                            (error) =>
                                error.messageCode === 'Invalid_Signature' ||
                                error.messageCode === 'Signature_Expired'
                        )
                    ) {
                    }
                });
        }
    }, [purchaseStage]);

    const update = useSelector(selectUIUpdate);

    useEffect(() => {
        if (update?.pushType === NotificationIdentifier.QUICKCRYPTO_CARD_PAYMENT_UPDATED) {
            instance
                .post<ApiResponse<PaymentInfo>>(endpoints.quickCryptoModule.getPaymentStatus, {
                    chargeCardIdempotencyKey: idempotencyKey,
                })
                .then((res) => {
                    setPaymentStatus(res.data.details.status);
                    setRedirectUrl(res.data.details.redirectUrl);
                });
            dispatch(completeUIUpdate());
        }
    }, [update]);
    useEffect(() => {
        if (!paymentStatus || paymentStatus !== 'Pending') return;
        const interval = setInterval(() => {
            instance
                .post<ApiResponse<PaymentInfo>>(endpoints.quickCryptoModule.getPaymentStatus, {
                    chargeCardIdempotencyKey: idempotencyKey,
                })
                .then((res) => {
                    if (res.data.details.status !== paymentStatus) {
                        setPaymentStatus(res.data.details.status);
                        setRedirectUrl(res.data.details.redirectUrl);
                    }
                });
        }, 15000);
        return () => clearInterval(interval);
    }, [paymentStatus]);

    useFIS();

    const validationSchema = Yup.object({
        quantityType: Yup.string().nullable(),
        cardType: Yup.string().nullable(),
        asset: Yup.string().required('Please choose a crypto'),
        currency: Yup.string().required('Please choose a currency'),
        amount: Yup.number().when('quantityType', {
            is: 'Amount',
            then: (schema) =>
                schema
                    .required('Please select an amount')
                    .min(
                        rules?.minAmount ?? 0,
                        `You must buy at least ${rules?.minAmount ?? 0} ${rules?.asset}`
                    )
                    .max(
                        rules?.maxAmount ?? Number.MAX_SAFE_INTEGER,
                        `You cannot buy more than ${rules?.maxAmount ?? Number.MAX_SAFE_INTEGER} ${
                            rules?.asset
                        }`
                    ),
        }),
        price: Yup.number().when('quantityType', {
            is: 'Price',
            then: (schema) =>
                schema
                    .required('Please set a price')
                    .min(
                        rules?.minPrice ?? 0,
                        `You must spend at least ${rules?.minPrice ?? 0} ${rules?.pairedAsset}`
                    )
                    .max(
                        rules?.maxPrice ?? Number.MAX_SAFE_INTEGER,
                        `You cannot spend more than ${rules?.maxPrice ?? Number.MAX_SAFE_INTEGER} ${
                            rules?.pairedAsset
                        }`
                    ),
        }),
        bAcceptedTermsAndConditions: Yup.boolean()
            .required('You have not accepted terms and conditions')
            .test('tc', 'You have not accepted terms and conditions', function (val) {
                return val === true;
            }),
    });

    const handleSubmit_New = async (
        values: CardPaymentFormValues_New,
        formikHelpers: FormikHelpers<CardPaymentFormValues_New>
    ) => {
        if (!encryption) return formikHelpers.setSubmitting(false);
        const { cardNumber, cvv, ...payload } = values;
        const cardDetails = { number: cardNumber.replace(/\s/g, ''), cvv };
        const { encryptedMessage, keyId } = await getTokenAndEncrypt(cardDetails, encryption);
        const {
            currency: currencyCode,
            asset: assetCode,
            amount: assetAmount,
            price: currencyAmount,
            quantityType,
            bSaveCardDetails: bSaveCard,
            bAcceptedTermsAndConditions,
            countryISOCode: countryISO2,
            cardProvider,
            ...rest
        } = { ...payload, ...basket };

        const [expMonth, expYear] = rest.expiryDate.split('/');
        await instance
            .post<ApiResponse<any>>(endpoints.quickCryptoModule.submitNewCardForm, {
                ...rest,
                encryptedContent: encryptedMessage,
                keyId,
                currencyCode,
                currencyAmount,
                expMonth,
                expYear: new Date().getFullYear().toString().slice(0, -2) + expYear,
                countryISO2,
                bSaveCard: bSaveCard ?? false,
                chargeCardIdempotencyKey: idempotencyKey,
                emailAddress,
                cardProvider,
            })
            .then((res) => {
                setPaymentReference(res.data.details.paymentReference);
                setPaymentStatus(res.data.details.status);
                setRedirectUrl(res.data.details.redirectUrl);
            })
            .catch((err) => {
                if (
                    isAxiosErrorHandled(err) &&
                    err.response.data.errors.some(
                        (error) =>
                            error.fieldName === 'Create_Payment' && error.messageCode === 'Failed'
                    )
                ) {
                    return setPaymentStatus('Failed');
                }
                handleGenericError(err, values, formikHelpers, setCardPaymentErrMsg);
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            });
    };

    const handleMobileWalletComplete = useCallback((result: PaymentInfo) => {
        setPaymentReference(result.paymentReference);
        setPaymentStatus(result.status);
        setRedirectUrl(result.redirectUrl);
    }, []);
    const getWpgUrl = async (
        values: PurchaseDetails,
        basket: PurchaseDetails | null,
        helpers: FormikHelpers<any>
    ) => {
        if (!basket) return helpers.setSubmitting(false);
        const { currency, asset, amount, price, quantityType } = basket;
        const { bSaveCard } = values;
        try {
            helpers.setSubmitting(true);
            const res = await instance.get<ApiResponse<{ iFrameUrl: string }>>(
                endpoints.quickCryptoModule.getPaymentIframe,
                {
                    params: {
                        asset,
                        currency,
                        quantityType,
                        bSaveCard,
                        price,
                        amount,
                    },
                }
            );
            helpers.setSubmitting(false);
            setIframeUrl(res.data.details.iFrameUrl);
            setPaymentStatus('WpgIframe');
        } catch {
            helpers.setSubmitting(false);
            setPaymentStatus('Failed');
        }
    };

    const handleSubmit = async (
        values: PurchaseDetails,
        formikHelpers: FormikHelpers<PurchaseDetails>
    ) => {
        const {
            currency: currencyCode,
            asset: assetCode,
            amount: assetAmount,
            price: currencyAmount,
            quantityType,
            bAcceptedTermsAndConditions,
        } = values;
        try {
            if (paymentFlow === PaymentFlowType.Form) {
                const res = await instance.post(endpoints.quickCryptoModule.submitExchangeOptions, {
                    bAcceptedTermsAndConditions,
                    currencyCode,
                    assetCode,
                    assetAmount,
                    currencyAmount,
                    quantityType,
                    bSaveCard: true,
                    chargeCardIdempotencyKey: idempotencyKey,
                    currency: currencyCode,
                    asset: assetCode,
                    amount: assetAmount,
                    price: currencyAmount,
                });
                setDeviceSessionId(res.data.details.deviceSessionId);
                setEmailAddress(res.data.details.emailAddress);
            }
            setPurchaseStage('checkout-stage'); //last step
            setBasket(values);
            formikHelpers.setSubmitting(false);
        } catch (err) {
            console.error(err);
            handleGenericError(err, values, formikHelpers, setCardPaymentErrMsg);
            formikHelpers.setSubmitting(false);
        }
    };

    if (!paymentFlow || !encryption || !idempotencyKey) return <></>;

    const generateCurrencyOptions = (asset: string) =>
        asset
            ? Object.keys(prices)
                  .filter(
                      (key) =>
                          key.split('/')[0] === asset &&
                          prices[key].availableOn?.includes(AvailableOnOptions.QuickCoin)
                  )
                  .map((key) => key.split('/')[1])
                  .map((key) => ({ label: key, value: key }))
            : [];

    return (
        <div className="FormPage HeightContent">
            <Formik<PurchaseDetails | (PurchaseDetails & (NewCardDetails | SavedCardDetails))>
                initialValues={
                    purchaseStage === 'basket-stage'
                        ? basket ?? initialValues
                        : cardType === 'Saved card'
                        ? {
                              ...initialValues_Saved,
                              ...(basket ?? initialValues),
                              cardType,
                          }
                        : {
                              ...initialValues_New,
                              ...(basket ?? initialValues),
                              cardType: 'New card',
                          }
                }
                onSubmit={async (values, helpers) => {
                    purchaseStage === 'basket-stage'
                        ? await handleSubmit(values, helpers)
                        : cardType === 'Saved card'
                        ? await handleSubmit_Saved({
                              paymentFlow,
                              encryption,
                              idempotencyKey,
                              setPaymentReference,
                              setPaymentStatus,
                              setRedirectUrl,
                              setCardPaymentErrMsg,
                          })(
                              values as CardPaymentFormValues_Saved,
                              helpers as FormikHelpers<CardPaymentFormValues_Saved>
                          )
                        : paymentFlow === PaymentFlowType.IFrame
                        ? await getWpgUrl(values, basket, helpers)
                        : await handleSubmit_New(
                              values as CardPaymentFormValues_New,
                              helpers as FormikHelpers<CardPaymentFormValues_New>
                          );
                }}
                validationSchema={
                    purchaseStage === 'basket-stage'
                        ? validationSchema
                        : paymentFlow === PaymentFlowType.IFrame
                        ? validationSchema_WpgNew
                        : cardType === 'Saved card'
                        ? validationSchema_Saved
                        : validationSchema_New
                }
            >
                {({ values, errors, setFieldTouched, setFieldValue, isSubmitting, touched }) => {
                    return !!basket && purchaseStage === 'checkout-stage' ? (
                        <CardPaymentForm
                            basket={basket}
                            setPurchaseStage={setPurchaseStage}
                            setCardType={setCardType}
                            setBasket={setBasket}
                            idempotencyKey={idempotencyKey}
                            encryption={encryption}
                            paymentFlow={paymentFlow}
                            paymentReference={paymentReference}
                            paymentStatus={paymentStatus}
                            setPaymentStatus={setPaymentStatus}
                            redirectUrl={redirectUrl}
                            cardPaymentErrMsg={cardPaymentErrMsg}
                            setCardPaymentErrMsg={setCardPaymentErrMsg}
                            iframeUrl={iframeUrl}
                            onMobileWalletComplete={handleMobileWalletComplete}
                            deviceSessionId={deviceSessionId}
                            midConfig={midConfig}
                        />
                    ) : (
                        <Form className="QuickCoinForm">
                            <RuleUpdater updateRules={updateRules} />
                            <WrappedFormSingleSelectField<PurchaseDetails>
                                fieldName={'asset'}
                                options={cryptoOptions}
                                label={'Crypto to buy'}
                            />
                            <WrappedFormSingleSelectField<PurchaseDetails>
                                fieldName={'currency'}
                                options={generateCurrencyOptions(values.asset)}
                                key={values.currency} // lets us reset the dropdown when currency = ""
                                label="Currency to purchase with"
                            />
                            <FiatToggle currencyCode={values.currency} assetCode={values.asset} />
                            <FormTextField
                                type="number"
                                disableFormikBlur
                                onBlur={() => {
                                    const { amount, price } = touched;
                                    !amount && setFieldTouched('amount', true);
                                    !price && setFieldTouched('price', true);
                                }}
                                step={
                                    values.quantityType === 'Price'
                                        ? 'any'
                                        : 1 / 10 ** (rules?.maxDecimalPrecision ?? 4)
                                }
                                autoComplete={false}
                                label={
                                    values.quantityType === 'Price'
                                        ? `Target price${
                                              values.currency ? ` in ${values.currency}` : ''
                                          }`
                                        : `Amount${values.asset ? ` of ${values.asset}` : ''}`
                                }
                                hint={
                                    values.quantityType === 'Price'
                                        ? rules?.maxPrice != null && rules.minPrice != null
                                            ? `min: ${rules?.minPrice}, max: ${rules.maxPrice}`
                                            : undefined
                                        : rules?.maxAmount != null && rules?.minAmount != null
                                        ? `min: ${rules?.minAmount}, max: ${rules?.maxAmount}`
                                        : undefined
                                }
                                field={values.quantityType === 'Price' ? 'price' : 'amount'}
                                required={true}
                                key={values.quantityType === 'Price' ? 'priceField' : 'amountField'}
                            />
                            <CalculatedPriceField
                                label={
                                    values.quantityType === 'Price'
                                        ? `Amount${values.asset ? ` of ${values.asset}` : ''}`
                                        : `Price${values.currency ? ` in ${values.currency}` : ''}`
                                }
                                isFiat={values.quantityType === 'Price'}
                                hint={
                                    values.quantityType === 'Amount'
                                        ? rules?.maxPrice != null && rules.minPrice != null
                                            ? `min: ${rules?.minPrice}, max: ${rules.maxPrice}`
                                            : undefined
                                        : rules?.maxAmount != null && rules?.minAmount != null
                                        ? `min: ${rules?.minAmount}, max: ${rules?.maxAmount}`
                                        : undefined
                                }
                                getAmountUrl={endpoints.quickCryptoModule.getAmount}
                                getPriceUrl={endpoints.quickCryptoModule.getPrice}
                            />
                            <div
                                className={`TermsConditions ${
                                    values.bAcceptedTermsAndConditions !== true
                                        ? 'NotAcceptedErrLabel'
                                        : ''
                                } ${cardPaymentErrMsg ? 'StagedPaymentError' : ''}`}
                            >
                                <div className={'RadioButtons'}>
                                    <RadioButton
                                        label={
                                            "By clicking the Buy button below, you agree to Ibanera's <a href='https://www.ibanera.com/legal-agreements/usa-general-terms-conditions/'>Terms & Conditions</a>, <a href='https://www.ibanera.com/legal-agreements/digital-asset-custody-terms-conditions/'>Digital Asset Custody Terms & Conditions</a> and <a href= 'https://www.ibanera.com/privacy-policy/'>Privacy Policy</a>"
                                        }
                                        selected={values.bAcceptedTermsAndConditions}
                                        onClick={() => {
                                            setFieldValue(
                                                'bAcceptedTermsAndConditions',
                                                !values.bAcceptedTermsAndConditions
                                            );
                                        }}
                                        bDangerousHtml={true}
                                        classname="terms-conditions"
                                    />
                                </div>
                                {errors.bAcceptedTermsAndConditions && (
                                    <div className="ErrorLabel">
                                        {errors.bAcceptedTermsAndConditions}
                                    </div>
                                )}
                            </div>
                            <div className="ErrorLabel StagedPayment">{cardPaymentErrMsg}</div>
                            <Button type="submit" variety="full" disabled={isSubmitting}>
                                Buy {values.asset}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const RuleUpdater = ({
    updateRules,
}: {
    updateRules: (assetCode: string, currency: string) => void;
}) => {
    const { values } = useFormikContext<PurchaseDetails>();
    useEffect(() => {
        updateRules(values.asset, values.currency);
    }, [values.asset, values.currency, updateRules]);
    return null;
};

export const handleGenericError = (
    err: any,
    values: any, //validationSchema.fields
    formikHelpers: FormikHelpers<any>,
    setErrMsg?: (msg: string) => void,
    errMsg?: string
) => {
    if (isAxiosErrorHandled(err) && err.response.data.errors) {
        if (
            err.response.data.errors?.some(
                (error) =>
                    error.fieldName === 'ExpYear' ||
                    error.fieldName === 'ExpMonth' ||
                    Object.keys(values).includes(toCamelCase(error.fieldName) as string)
            )
        ) {
            //or filtered.forEach
            err.response.data.errors.forEach((error) => {
                const fieldName = toCamelCase(error.fieldName) as string;
                if (error.fieldName === 'ExpYear' || error.fieldName === 'ExpMonth') {
                    formikHelpers.setFieldError('expiryDate', getErrorMessage(error.messageCode));
                } else if (Object.keys(values).includes(fieldName)) {
                    formikHelpers.setFieldError(fieldName, getErrorMessage(error.messageCode));
                }
            });
        } else {
            if (err.response.data?.errors?.length > 0) {
                const errors = err.response.data.errors;
                setErrMsg &&
                    setErrMsg(
                        errors[0].messageCode === 'Failed' &&
                            (errors[0].fieldName === 'Create_Payment' ||
                                errors[0].fieldName === 'Create_Card')
                            ? 'There may be something incorrect with card details. Please check your entries'
                            : Object.keys(ERROR_CODES).includes(errors[0].messageCode)
                            ? getErrorMessage(errors[0].messageCode)
                            : errMsg ?? 'There is a problem. Please try again later'
                    );
            } else setErrMsg && setErrMsg(getErrorMessage(errMsg ? '' : 'Generic'));
        }
    }
};
