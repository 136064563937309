// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const PDFViewer = ({ fileUrl }: { fileUrl: string }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const pdfRef = useRef(null);
    const [zoomLevel, setZoomLevel] = useState<number>(100);
    const canvasRefs = useRef<HTMLCanvasElement[]>([]);
    useEffect(() => {
        const loadPDF = async () => {
            pdfRef.current = await pdfjsLib.getDocument(fileUrl).promise;
            const numPages = pdfRef.current.numPages;
            for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                const page = await pdfRef.current.getPage(pageNum);
                const viewport = page.getViewport({ scale: 1.5 });
                const canvas = document.createElement('canvas');
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                canvas.style.margin = '0';
                canvas.style.padding = '0';
                canvas.style.display = 'block';
                const context = canvas.getContext('2d');
                const renderContext = {
                    canvasContext: context,
                    viewport,
                };
                await page.render(renderContext).promise;
                canvasRefs.current.push(canvas);
                containerRef.current?.appendChild(canvas);
            }
        };
        loadPDF();
    }, [fileUrl]);
    return (
        <div style={{ position: 'relative' }}>
            <div
                ref={containerRef}
                id="pdf-container"
                style={{
                    width: `${zoomLevel}%`,
                    overflow: 'auto',
                    padding: '0',
                    margin: '0',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            ></div>
            <div
                style={{
                    position: 'fixed', 
                    top: '10px',
                    right: '10px',
                    zIndex: 10,
                    padding: '5px',
                    borderRadius: '5px',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', 
                }}
            >
                <button
                    onClick={() => setZoomLevel((prev) => Math.min(prev + 10, 300))}
                    style={{
                        backgroundColor: 'rgba(0, 0, 255, 0.5)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '2px 10px',
                        cursor: 'pointer',
                        fontSize: '20px',
                        marginRight: '5px',
                    }}
                >
                    +
                </button>
                <button
                    onClick={() => setZoomLevel((prev) => Math.max(prev - 10, 10))}
                    style={{
                        backgroundColor: 'rgba(0, 0, 255, 0.5)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '2px 10px',
                        cursor: 'pointer',
                        fontSize: '20px',
                    }}
                >
                    -
                </button>
            </div>
        </div>
    );
};
export default PDFViewer;